import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Internationaldaybreadcreumb from "../components/resources/International-day/international-day-breadcumb.jsx";

import InternationaldayContentpage from "../components/resources/International-day/international-day-content.jsx";


// markup
const Internationalday = () => {
  return (
    <div>
      <Seo title="Humans Of Heartfulness" />

      <Layout isSticky>
        <section>
          <Internationaldaybreadcreumb />
        </section>

        <section>
          <InternationaldayContentpage/>
        </section>
      </Layout>
    </div>
  );
};

export default Internationalday;
